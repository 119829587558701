import LabeledSelect from '../shared/Inputs/LabeledSelect'
import SecSwitch from '../shared/Inputs/SecSwitch'
import SecTextField from '../shared/Inputs/SecTextField'
import PageTitleWrapper from '../shared/PageTitleWrapper'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material'

import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'

import {Delete, Edit, Save} from '@mui/icons-material'

import measurePointService from '../../services/measurepointService'
import {hasPermissions, isAdmin, isNullOrWhiteSpace, sortSelectItems} from '../../shared/utils/utilities'

import {SaveResponse} from '../../shared/enums/SaveResponse'
import SaveAlert from '../shared/alerts/SaveAlert'
import SecButton from '../shared/Inputs/SecButton'
import CounterDeviationDetails from './deviation/CounterDeviationDetails'
import CounterIntegrationList from './CounterIntegrationList'
import SearchSelectDialog, {ISelectItem} from '../shared/dialogs/SearchSelectDialog'

import {ICounterAttribute, ICounterAttributeParameter, ICounterDetailsProps, IInactivePeriod} from '../../redux/counter/interfaces'
import {IMeasurePoint, MeasurePointSearchResultDto} from '../../redux/Measurepoint/measurepointInterfaces'
import {ICounterDeviation, IDeviationType} from '../../redux/counter/interfaces'

import {RootState} from '../../redux/rootReducer'

import {ActionTypes} from '../../redux/counter/types'

import {getDeviationTypeForMeter, getDeviationValueTypes} from '../../redux/meter/actions'
import {
  getCounterDeviation,
  deleteCounter,
  getCounterNumbers,
  getInactivePeriods,
  getIsCounterExcluded,
  getIsCounterMultiValue,
  toggleInactivePeriods,
  updateCounter,
  getCounterIntegrations,
  updateCounterDeviation,
  getCounterAttributes,
  getCounterAttributeControls,
  getMeterType,
  setUpdatedCounterDeviation,
} from '../../redux/counter/actions'
import {getMeasurePointById} from '../../redux/Measurepoint/measurepointActions'
import {getCounterExternalSystems} from '../../redux/externalSystem/actions'
import AttributeControlPanel from '../attributes/controls/AttributeControlPanel'
import {HEAT_EXCHANGER_TYPES, NODE_WRITE, UNDEFINED_SWE} from '../../shared/utils/constants'
import {IAttributeControl} from '../../redux/attribute/interfaces'

const defaultInactivePeriods: IInactivePeriod[] = [
  {period: 1, isInactive: false, label: 'Jan'},
  {period: 2, isInactive: false, label: 'Feb'},
  {period: 3, isInactive: false, label: 'Mar'},
  {period: 4, isInactive: false, label: 'Apr'},
  {period: 5, isInactive: false, label: 'Maj'},
  {period: 6, isInactive: false, label: 'Jun'},
  {period: 7, isInactive: false, label: 'Jul'},
  {period: 8, isInactive: false, label: 'Aug'},
  {period: 9, isInactive: false, label: 'Sep'},
  {period: 10, isInactive: false, label: 'Okt'},
  {period: 11, isInactive: false, label: 'Nov'},
  {period: 12, isInactive: false, label: 'Dec'},
]

const emptyDeviationValue: ICounterDeviation = {
  id: 0,
  counterId: 0,
  deviation: 10,
  insDate: new Date(),
  updDate: new Date(),
  deviationValues: [],
}

const CounterDetails = (props: ICounterDetailsProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [saveResultAlertOpen, setSaveResultAlertOpen] = useState(false)
  const [importResultAlertOpen, setImportResultAlertOpen] = useState(false)
  const [counterSaveResponse, setCounterSaveResponse] = useState(SaveResponse.NOT_SET)
  const [importResultSaveResponse, setImportResultSaveResponse] = useState(SaveResponse.NOT_SET)
  const [deleteCounterDialogOpen, setDeleteCounterDialogOpen] = useState<boolean>(false)
  const [selectMeasurePointDialogOpen, setSelectMeasurePointDialogOpen] = useState<boolean>(false)

  const availableTypes = useSelector((state: RootState) => state.filter.types)
  const lastActionType = useSelector((state: RootState) => state.counter.lastActionType)
  const importResult = useSelector((state: RootState) => state.counter.integrationImportResult)
  const inactivePeriods = useSelector((state: RootState) => state.counter.inactivePeriods)
  const counterNumbers = useSelector((state: RootState) => state.counter.numbers)
  const counterIsExcluded = useSelector((state: RootState) => state.counter.isExcluded)

  const deviation = useSelector((root: RootState) => root.counter.deviation)
  const meterDeviationType = useSelector((root: RootState) => root.meter.selectedDeviationType)

  const integrations = useSelector((state: RootState) => state.counter.integrations)
  const externalSystems = useSelector((state: RootState) => state.externalSystem.externalSystems)
  const isLoading = useSelector((state: RootState) => state.counter.isLoading)
  const measurePoint = useSelector((state: RootState) => state.measurepoint.selectedMeasurePoint)
  const valueTypes = useSelector((state: RootState) => state.meter.deviationValueTypes)
  //const modifiedDeviation = useSelector((state: RootState) => state.counter.modifiedDeviation)
  const counterAttributes = useSelector((state: RootState) => state.counter.counterAttributes)
  const attributeControls = useSelector((state: RootState) => state.counter.attributeControls)
  const meterType = useSelector((state: RootState) => state.counter.type)
  const counter = props.counter

  const [counterId] = useState<number>(counter.id)
  const [counterName, setCounterName] = useState(counter.name)
  const [counterNumber, setCounterNumber] = useState('')
  const [counterConstant, setCounterConstant] = useState(1)
  const [parentCounterId, setParentCounterId] = useState(0)
  const [subCounterId, setSubCounterId] = useState(0)
  const [isExcluded, setIsExcluded] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [selectedType, setSelectedType] = useState<number>(counter.meterId)
  const [selectedMeasurePointId, setSelectedMeasurePointId] = useState<number>(counter.measurePointId)
  const [counterAttributesToSave, setCounterAttributesToSave] = useState<ICounterAttributeParameter[]>([])
  const [canEdit, setCanEdit] = useState<boolean>(false)
  const [hasAdminRights, setHasAdminRights] = useState<boolean>(false)

  const [changedDeviation, setChangedDeviation] = useState<ICounterDeviation | undefined>()
  const [currentDeviation, setCurrentDeviation] = useState<ICounterDeviation | undefined>()

  const [selectedHeatExchangerCategory, setSelectedHeatExchangerCategory] = useState<string>('')

  useEffect(() => {
    if (deviation?.counterId === props.counter.id) {
      setCurrentDeviation(deviation)
      setChangedDeviation(currentDeviation)
    } else {
      setCurrentDeviation(undefined)
      setChangedDeviation(undefined)
    }
  }, [deviation])

  useEffect(() => {
    if(lastActionType === ActionTypes.FETCH_COUNTER_IS_EXCLUDED_SUCCESS || lastActionType === ActionTypes.FETCH_COUNTER_IS_EXCLUDED_FAILED) {
      setIsExcluded(counterIsExcluded)
    }
  }, [counterIsExcluded, lastActionType])

  useEffect(() => {
    setSaveResultAlertOpen(false)
    setSelectedHeatExchangerCategory('')
    setCounterNumber('')
    setChangedDeviation(undefined)
    setIsExcluded(false)
    setCounterSaveResponse(SaveResponse.NOT_SET)
    setIsDisabled(props.counter.hidden)
    setCounterName(props.counter.name)
    setCounterConstant(props.counter.constant)
    setSelectedType(props.counter.meterId)
    setCounterConstant(props.counter.constant)
    setSelectedMeasurePointId(props.counter.measurePointId)
    dispatch(getIsCounterExcluded(props.counter.id))
    dispatch(getCounterIntegrations(props.counter.id))
    dispatch(getIsCounterMultiValue(props.counter.id))
    dispatch(getInactivePeriods(props.counter.id))
    dispatch(getCounterNumbers(props.counter.id))
    dispatch(getCounterExternalSystems())
    dispatch(getMeasurePointById(props.counter.measurePointId))
    dispatch(getDeviationTypeForMeter(props.counter.meterId))
    dispatch(getDeviationValueTypes())
    dispatch(getCounterAttributes(props.counter.id))
    dispatch(getCounterAttributeControls())
    dispatch(getMeterType(props.counter.meterId))
  }, [props.counter.id])

  useEffect(() => {
    dispatch(getCounterDeviation(props.counter.id))
  }, [counterAttributes])

  useEffect(() => {
    if (measurePoint) {
      setSelectedMeasurePointId(measurePoint.id)
    }
  }, [measurePoint])

  useEffect(() => {
    setCounterNumber(counterNumbers ? counterNumbers.join(',') : '')
  }, [counterNumbers])

  useEffect(() => {
    if (counterSaveResponse === SaveResponse.NOT_SET && lastActionType === ActionTypes.UPDATE_COUNTER_SUCCESS) {
      setSaveResultAlertOpen(true)
      setCounterSaveResponse(SaveResponse.SUCCESS)
    } else if (counterSaveResponse === SaveResponse.NOT_SET && lastActionType === ActionTypes.UPDATE_COUNTER_FAILED) {
      setSaveResultAlertOpen(true)
      setCounterSaveResponse(SaveResponse.FAIL)
    }
  }, [lastActionType])

  useEffect(() => {
    if (importResult && importResult.length > 0) {
      if (lastActionType === ActionTypes.UPDATE_EXTERNAL_DATA_SUCCESS) {
        setImportResultAlertOpen(true)
        setImportResultSaveResponse(SaveResponse.SUCCESS)
      } else if (lastActionType === ActionTypes.UPDATE_EXTERNAL_DATA_FAILED) {
        setImportResultAlertOpen(true)
        setImportResultSaveResponse(SaveResponse.FAIL)
      }
    }
  }, [lastActionType, importResult])

  useEffect(() => {
    if (!isNullOrWhiteSpace(selectedHeatExchangerCategory)) {
      const heatExchangerType = HEAT_EXCHANGER_TYPES.find((x: any) => x.name.toLocaleLowerCase() === selectedHeatExchangerCategory.toLocaleLowerCase())
      if (heatExchangerType && heatExchangerType.name !== UNDEFINED_SWE) {
        setChangedDeviation((prev) =>
          prev ? {...prev, counterId: props.counter.id, deviation: heatExchangerType.value} : {...emptyDeviationValue, counterId: props.counter.id, deviation: heatExchangerType.value}
        )
      }
    }
  }, [selectedHeatExchangerCategory])

  useEffect(() => {
    setCanEdit(hasPermissions([NODE_WRITE]))
    setHasAdminRights(isAdmin())
  }, [])

  const onAttributeChange = (attributeId: number, value: string) => {
    if (value === UNDEFINED_SWE) return
    const attributesById = counterAttributes.filter((x) => x.attributeId === attributeId)
    const previousAttributeValue = attributesById.length > 0 ? attributesById[0].value : ''
    setCounterAttributesToSave((prev: ICounterAttributeParameter[]) => {
      const index = prev.findIndex((x) => x.attributeId === attributeId && x.counterId === props.counter.id)
      if (index >= 0) {
        const updatedItems = [...prev]
        updatedItems[index].value = value
      } else {
        return [...prev, {counterId: props.counter.id, attributeId, value}]
      }
      return prev
    })
    const heatExchangerType = HEAT_EXCHANGER_TYPES.find((x: any) => x.name.toLocaleLowerCase() === value.toLocaleLowerCase())
    if (heatExchangerType) {
      setSelectedHeatExchangerCategory(heatExchangerType.name)
    }
  }

  const renderPeriodSwitch = (obj: IInactivePeriod, index: number) => {
    //const isInactive = inactivePeriods?.some((x) => x.stopDate.getMonth() + 1 === obj.period)
    return (
      <Grid item xs={1} key={index}>
        <SecSwitch label={obj.label}>
          <Switch checked={obj.isInactive} onChange={(e) => dispatch(toggleInactivePeriods(e.target.checked, index + 1))} title='Avläses ej' />
        </SecSwitch>
      </Grid>
    )
  }

  const renderDeviationDetails = () => {
    if (props.counter.id && props.counter.id > 0) {
      return (
        <CardContent>
          {changedDeviation === undefined || changedDeviation.counterId === 0 || changedDeviation.counterId === props.counter.id ? (
            <CounterDeviationDetails
              counterId={props.counter.id}
              deviation={changedDeviation ? changedDeviation : emptyDeviationValue}
              meterDeviationType={meterDeviationType}
              valueTypes={valueTypes}
              onChange={(d: ICounterDeviation) => {
                setCurrentDeviation(d)
              }}
            />
          ) : (
            <CircularProgress sx={{color: '#223354'}} size={64} />
          )}
        </CardContent>
      )
    } else {
      return ''
    }
  }

  const onSaveCounterButtonClick = () => {
    if (selectedType <= 0) return
    // setListenForAttributeChanges(false)
    const measurePointId = measurePoint ? measurePoint.id : counter.measurePointId
    const counterAttributeParameters = counterAttributesToSave.map((x: ICounterAttributeParameter) => ({counterId: x.counterId, attributeId: x.attributeId, value: x.value}))

    dispatch(
      updateCounter(
        counter.id,
        measurePointId,
        selectedType,
        counterName,
        counter.turned,
        counterConstant,
        isDisabled,
        isExcluded,
        counterNumber,
        integrations,
        counterAttributeParameters,
        inactivePeriods
      )
    )
    if (currentDeviation) {
      if (currentDeviation.counterId === 0 || currentDeviation.counterId === counter.id) {
        dispatch(updateCounterDeviation(currentDeviation))
      }
    }
  }

  const onDeleteCounterClick = () => {
    setDeleteCounterDialogOpen(false)
    dispatch(deleteCounter(counter.id))
    navigate(`/measurePoint/${counter.measurePointId}`)
  }

  const onChangeMeasurePointClick = (e: React.MouseEvent<HTMLButtonElement>) => setSelectMeasurePointDialogOpen(true)
  const onChangeMeasurePointConfirmClick = (value: any) => {
    setSelectMeasurePointDialogOpen(false)
    if (value) {
      dispatch(getMeasurePointById(value))
    }
  }
  const onMeasurePointSearchPerformed = async (keyword: string) => {
    let result: ISelectItem[] = []
    if (keyword && keyword.trim().length >= 3) {
      let measurePoints = await measurePointService.searchMeasurePoint(keyword)
      if (measurePoints) {
        result = measurePoints.map((x: MeasurePointSearchResultDto) => {
          let measurePointPart = x.number ? x.number + ' ' + x.name : x.name
          let addressPart = ''
          let estatePart = ''
          if(x.address !== undefined) {
            addressPart = ` ${isNullOrWhiteSpace(x.address.name) ? `ID: ${x.address.id.toLocaleString()}` : x.address.name}`
            if(x.address.estate !== undefined) {
              estatePart =  ` ${isNullOrWhiteSpace(x.address.estate.name) ? `ID: ${x.address.estate.id.toLocaleString()}` : x.address.estate.name}`
              measurePointPart = `${estatePart} / ${addressPart} / ${measurePointPart}`
            }
            else {
              measurePointPart = `${addressPart} / ${measurePointPart}`
            }
          }

          const item: ISelectItem = {id: x.id, text: measurePointPart}
          return item
        })
        result = sortSelectItems(result)
      }
    }
    return result
  }

  const renderEditButton = () =>
    canEdit && (
      <Grid item xs={10}>
        <SecButton startIcon={<Save />} variant='contained' onClick={(e: React.MouseEvent<HTMLButtonElement>) => onSaveCounterButtonClick()}>
          Spara
        </SecButton>
      </Grid>
    )

  const renderDeleteButton = () =>
    hasAdminRights && (
      <Grid item xs={2}>
        <SecButton startIcon={<Delete />} variant='contained' onClick={(e: React.MouseEvent<HTMLButtonElement>) => setDeleteCounterDialogOpen(true)} color='error' sx={{float: 'right'}}>
          Radera
        </SecButton>
      </Grid>
    )

  const renderModifyButtons = () => (
    <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
      {renderEditButton()}
      {renderDeleteButton()}
    </Grid>
  )

  if (isLoading) {
    return (
      <React.Fragment>
        <Box display='flex' justifyContent='center' alignItems='center' minHeight='600px'>
          <CircularProgress sx={{color: '#223354'}} size={256} />
        </Box>
      </React.Fragment>
    )
  }
  return (
    <div>
      <PageTitleWrapper>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h3' component='h3' gutterBottom></Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </PageTitleWrapper>
      <Container maxWidth='lg'>
        <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
          <Grid item xs={12}>
            <SaveAlert
              result={counterSaveResponse}
              open={saveResultAlertOpen}
              onClose={() => {
                setSaveResultAlertOpen(false)
                setCounterSaveResponse(SaveResponse.NOT_SET)
              }}
              successMessage='Räkneverket har uppdaterats.'
              errorMessage='Misslyckades med att uppdatera Räkneverket.'
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
          <Grid item xs={12}>
            <SaveAlert
              result={importResultSaveResponse}
              open={importResultAlertOpen}
              onClose={() => {
                setImportResultAlertOpen(false)
                setImportResultSaveResponse(SaveResponse.NOT_SET)
              }}
              successMessage={`Importerade ${importResult?.length} avläsningar för räkneverk ${importResult && importResult.length > 0 ? importResult[0].counterId : 'N/A'}.`}
              errorMessage='Import av data misslyckades.'
            />
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='stretch' spacing={3}>
          <Grid item xs={12}>
            <Card component='form' noValidate autoComplete='off'>
              <CardHeader title='Redigera räkneverk' />
              <Divider />
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                    <Grid item xs={2}>
                      <TextField value={props.counter.id} type='number' fullWidth disabled={true} InputProps={{readOnly: true}} label='Id' />
                    </Grid>
                    <Grid item xs={3}>
                      <SecTextField fullWidth inputProps={{maxLength: 40}} value={counterName} onChange={(e: any) => setCounterName(e.target.value)} label='Namn' />
                    </Grid>
                    <Grid item xs={3}>
                      <SecTextField fullWidth inputProps={{maxLength: 40}} value={counterNumber} onChange={(e: any) => setCounterNumber(e.target.value)} label='Nummer' />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                    <Grid item xs={2}>
                      <SecTextField fullWidth inputProps={{maxLength: 20}} value={counterConstant} label='Multipliceras med' type='number' onChange={(e: any) => setCounterConstant(e.target.value)} />
                    </Grid>
                    <Grid item xs={3}>
                      <LabeledSelect
                        id='typeSelect'
                        label='Mätartyp'
                        className='type-select'
                        items={availableTypes.map((x) => ({value: x.id, text: x.name}))}
                        value={selectedType}
                        onChange={(e: any) => setSelectedType(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField fullWidth inputProps={{maxLength: 40}} value={selectedMeasurePointId} disabled={true} InputProps={{readOnly: true}} label='Mätpunkts Id' />
                    </Grid>
                    {hasAdminRights && (<Grid item xs={3}>
                      <SecTextField
                        value={measurePoint ? measurePoint.name : ''}
                        fullWidth
                        inputProps={{maxLength: 40}}
                        disabled={true}
                        label='Mätpunkt'
                        InputProps={{
                          readOnly: !canEdit,
                          endAdornment: (
                            <SecButton startIcon={<Edit />} onClick={onChangeMeasurePointClick}>
                              Ändra
                            </SecButton>
                          ),
                        }}
                      />
                    </Grid>)}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={5}>
                    {/* <Grid item xs={2}>
                                            <SecTextField                                            
                                                fullWidth
                                                inputProps={{maxLength: 40}}
                                                value={parentCounterId}
                                                label="Tillhör mätare"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <SecTextField                                            
                                                fullWidth
                                                inputProps={{maxLength: 40}}
                                                value={subCounterId}
                                                label="Undermätare"
                                            />
                                        </Grid> */}
                    <Grid item xs={2}>
                      <SecSwitch label='Exkludera i fastighetsförbrukning'>
                        <Switch checked={isExcluded} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIsExcluded(event.target.checked)} title='Exkludera i fastighetsförbrukning' />
                      </SecSwitch>
                    </Grid>
                    <Grid item xs={2}>
                      <SecSwitch label='Avläses ej'>
                        <Switch checked={isDisabled} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIsDisabled(event.target.checked)} title='Avläses ej' />
                      </SecSwitch>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              {renderDeviationDetails()}
              {meterType && meterType.name === 'Värmeväxlare' ? (
                <CardContent>
                  <AttributeControlPanel
                    id={props.counter.id}
                    values={counterAttributes}
                    attributeControls={attributeControls}
                    onChange={(attributeId: number, value: string) => onAttributeChange(attributeId, value)}
                    ignoreDefaults={true}
                    disabled={!canEdit}
                  />
                </CardContent>
              ) : (
                ''
              )}
              <Divider />
              <CardHeader title='Inaktiva perioder' />
              {inactivePeriods && inactivePeriods.length > 0 ? (
                <CardContent>
                  <Grid container spacing={5}>
                    <Grid item xs={12} container justifyContent='flex-start' direction='row' spacing={2}>
                      {inactivePeriods.map((obj, index) => {
                        return renderPeriodSwitch(obj, index)
                      })}
                    </Grid>
                  </Grid>
                </CardContent>
              ) : (
                ''
              )}
              <Divider />
              <CardContent>
                <CounterIntegrationList counter={counter} integrations={integrations} externalSystems={externalSystems} />
              </CardContent>
              <Divider />
              <Divider />
              <CardContent>{renderModifyButtons()}</CardContent>
            </Card>
          </Grid>
        </Grid>
        <div>
          <Dialog open={deleteCounterDialogOpen} onClose={() => setDeleteCounterDialogOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>{'Radera mätare'}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                Vill du radera mätaren {isNullOrWhiteSpace(counter.name) ? `${counter.name}(${counter.id})` : `med ID: ${counter.id}`}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onDeleteCounterClick} autoFocus>
                Radera
              </Button>
              <Button onClick={() => setDeleteCounterDialogOpen(false)}>Avbryt</Button>
            </DialogActions>
          </Dialog>
        </div>
        <SearchSelectDialog
          open={selectMeasurePointDialogOpen}
          title='Flytta mätare'
          defaultItemLabel='-- Välj mätpunkt --'
          text='Välj den mätpunkt till vilken du vill flytta mätaren.'
          onConfirm={onChangeMeasurePointConfirmClick}
          onCancel={() => setSelectMeasurePointDialogOpen(false)}
          onSearch={onMeasurePointSearchPerformed}
        />
      </Container>
    </div>
  )
}
export default CounterDetails
